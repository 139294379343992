import { IClubContentPage } from 'src/features/shared/contentful/types/IClubContentPage';
import { IContentPage } from 'src/features/shared/contentful/types/IContentPage';
import { Club } from 'src/features/shared/types/Club';
import { IJobVacancy } from 'src/features/shared/types/IJobVacancy';
import { ProxyClient } from './client';
import { mapVoucher } from '@services/perfect-gym/mappers/map-voucher';
import {
  ContractParams,
  Discount,
  PaymentPlan,
  SignUpData,
} from '../perfect-gym/types';
import axios from 'axios';

export async function getClubs() {
  return await ProxyClient.get('/clubs');
}

export async function getClubById(id: number | string) {
  return await ProxyClient.get<Club.MapCard>(`/clubs/${id}`);
}

export async function getPage(slug: string) {
  return await ProxyClient.get<IClubContentPage | IContentPage>(
    `/pages/${slug}`,
  );
}

export async function getClubOpeningHoursByClubId(id: number | string) {
  return await ProxyClient.get(`/perfect-gym/club-opening-hours/${id}`);
}

export async function getClubOpeningHoursExceptionsByClubId(
  id: number | string,
) {
  return await ProxyClient.get(
    `/perfect-gym/club-opening-hours-exceptions/${id}`,
  );
}

export async function getMembershipsByClubId(clubId: number | string) {
  return await ProxyClient.get(`/memberships/${clubId}`);
}

export async function getFriendsConfirmationCode(code: string) {
  return await ProxyClient.get(`/friend-code/${code}`);
}

export async function getAddressByPostalCode(
  postcode: string,
  houseNumber: string,
  addition: string,
) {
  return await ProxyClient.get(
    `/postcode-nl/${postcode}/${houseNumber}${addition ? `/${addition}` : ''}`,
  );
}

export async function getDiscountsByIds(discountIds: number[]) {
  return await ProxyClient.get<Discount[]>(
    `/perfect-gym/discounts?isActive=1&withClubs=1&discountIds=${discountIds.join(
      ',',
    )}`,
  );
}

export async function getPaymentsByIds(paymentPlanIds: number[]) {
  return await ProxyClient.get<PaymentPlan[]>(
    `/perfect-gym/contract/payments?ids=${paymentPlanIds}`,
  );
}

export async function getJobVacancies() {
  return await ProxyClient.get<IJobVacancy[]>('/homerun');
}

export function getCheckoutClubs(params?: { checkoutConfigId?: string }) {
  return ProxyClient.get<Club.MapCard[]>('/checkout/clubs', {
    params,
  }).then((res) => res.data);
}

export async function getVoucher(contractData: ContractParams) {
  const { data } = await ProxyClient.post(
    '/perfect-gym/voucher-code',
    contractData,
  );
  return mapVoucher(data);
}

export const ERROR_CODES = ['blacklisted', 'frozen_contract', 'open_contract'];
export const USER_ERROR_CODES = ['payment_failed', 'validation_error'];

export interface ISignupError {
  code?: (typeof ERROR_CODES)[number] | (typeof USER_ERROR_CODES)[number];
  message?: string;
}

interface IPostSignupData {
  paymentLink: string;
  error?: ISignupError;
}

interface IPostSignupResponse {
  data: IPostSignupData | null;
  statusCode: number;
}

export async function postSignup(
  signUpData: SignUpData,
): Promise<IPostSignupResponse> {
  try {
    const { data, status } = await ProxyClient.post<IPostSignupData>(
      '/perfect-gym/signup',
      signUpData,
    );
    return { data, statusCode: status };
  } catch (error) {
    let data;
    let status;

    if (axios.isAxiosError(error)) {
      data = error.response?.data;
      status = error.response?.status;
    }

    return { data: data, statusCode: status ?? 500 };
  }
}

export function disableDraftMode() {
  return ProxyClient.delete('/draft');
}
